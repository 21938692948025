var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12","sm":_vm.currentSize.sm,"md":_vm.currentSize.md}},[_c('v-card',{attrs:{"flat":""}},[_c('v-app-bar',{attrs:{"dense":"","flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h6 pl-0"},[_vm._v(_vm._s(_vm.$t('dashboard.widgets.calendar')))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.resize({ type: 'calendar' })}}},on),[_c('v-icon',[_vm._v("mdi-arrow-expand-horizontal")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.resize')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"drag-widget",attrs:{"small":"","icon":""}},on),[_c('v-icon',[_vm._v("mdi-drag")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.move')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.close({ type: 'calendar' })}}},on),[_c('v-icon',{staticStyle:{"cursor":"pointer"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.remove')))])])],1),_c('v-app-bar',{attrs:{"dense":"","flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){_vm.type = 'month'}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-month")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.calendar.monthView')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){_vm.type = 'week'}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-week")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.calendar.weekView')))])]),_c('v-spacer'),_c('v-toolbar-title',{staticClass:"text-h6 pl-0"},[_vm._v(_vm._s(_vm.dateLabel))]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":_vm.prev}},[_c('v-icon',{staticStyle:{"cursor":"pointer"}},[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":_vm.next}},[_c('v-icon',{staticStyle:{"cursor":"pointer"}},[_vm._v("mdi-chevron-right")])],1)],1),_c('v-sheet',{attrs:{"height":"500"}},[_c('v-calendar',{ref:"calendar",attrs:{"weekday-format":_vm.weekday,"month-format":_vm.month,"color":"secondary","events":_vm.visibleEvents,"event-color":_vm.getEventColor,"event-more-text":_vm.$tc('dashboard.calendar.moreEvents', '{0}', { count: '{0}' }),"type":_vm.type,"weekdays":[1, 2, 3, 4, 5, 6, 0]},on:{"click:event":_vm.showEvent,"click:more":_vm.viewDay,"click:date":_vm.viewDay,"mouseenter:event":_vm.openEventPopup,"mouseleave:event":_vm.closeEventPopup,"change":_vm.updateRange},model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1)],1),_c('v-tooltip',{attrs:{"position-x":_vm.popupPositionX,"position-y":_vm.popupPositionY,"right":""},model:{value:(_vm.popupVisible),callback:function ($$v) {_vm.popupVisible=$$v},expression:"popupVisible"}},[_c('span',[_vm._v(_vm._s(_vm.popupContent))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
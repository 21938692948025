var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12","sm":_vm.currentSize.sm,"md":_vm.currentSize.md}},[_c('v-card',{attrs:{"flat":""}},[_c('v-app-bar',{attrs:{"dense":"","flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h6 pl-0"},[_vm._v(_vm._s(_vm.$t('dashboard.todos.title'))+" ("+_vm._s(_vm.visibleItems.length)+")")]),_c('v-spacer'),(_vm.invisibleItems && _vm.invisibleItems.length > 0)?_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","close-on-content-click":false,"transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),Object.assign({}, onMenu, onTooltip)),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$tc('dashboard.todos.unhide', _vm.invisibleItems.length, { count: _vm.invisibleItems.length }))+" ")])])]}}],null,false,1030351806)},[_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item-group',[_vm._l((_vm.invisibleItems),function(item){return [_c('v-list-item',{key:((item.processId) + " " + (item.feedbackId) + " " + (item.task)),on:{"click":function($event){return _vm.setTaskVisible($event, item, true)}}},[[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"font-weight":"bold"},domProps:{"textContent":_vm._s(
                        (item.optional ? ' Optional: ' : '') +
                        _vm.$t(
                          ("dashboard.feedbackTasks." + (item.processType) + "." + (item.task))
                        )
                      )}}),_c('v-list-item-subtitle',{staticClass:"text--primary",domProps:{"textContent":_vm._s(
                        _vm.$t(("feedbacks.processTypes." + (item.processType)))
                      )}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(
                        ((item.recipientFirstName) + " " + (item.recipientLastName))
                      )}})],1)]],2)]})],2)],1)],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.resize({ type: 'todos' })}}},on),[_c('v-icon',[_vm._v("mdi-arrow-expand-horizontal")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.resize')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"drag-widget",attrs:{"small":"","icon":""}},on),[_c('v-icon',[_vm._v("mdi-drag")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.move')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.close({ type: 'todos' })}}},on),[_c('v-icon',{staticStyle:{"cursor":"pointer"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.remove')))])])],1),_c('v-sheet',{staticStyle:{"overflow":"auto"},attrs:{"height":"450"}},[_c('v-list',{attrs:{"two-line":""}},[(_vm.visibleItems && _vm.visibleItems.length > 0)?_c('v-list-item-group',[_vm._l((_vm.visibleItems),function(item,index){return [_c('v-list-item',{key:((item.processId) + " " + (item.feedbackId) + " " + (item.task)),on:{"click":function($event){return _vm.goToTask(item)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"font-weight":"bold"},domProps:{"textContent":_vm._s(
                      (item.optional ? ' Optional: ' : '') +
                      _vm.$t(
                        ("dashboard.feedbackTasks." + (item.processType) + "." + (item.task))
                      )
                    )}}),_c('v-list-item-subtitle',{staticClass:"text--primary",domProps:{"textContent":_vm._s(_vm.$t(("feedbacks.processTypes." + (item.processType))))}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(
                      ((item.recipientFirstName) + " " + (item.recipientLastName))
                    )}})],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.setTaskVisible($event, item, false)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-eye-off ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('dashboard.todos.hide')))])]),(!active)?_c('v-icon',[_vm._v(" mdi-arrow-right ")]):_c('v-icon',[_vm._v(" mdi-arrow-right ")])],1)]}}],null,true)}),_c('v-divider',{key:index})]})],2):(_vm.loading)?_c('v-list-item-group',[_c('v-list-item',[_c('v-list-item-content',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"secondary"}})],1)],1)],1):_c('v-list-item-group',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('dashboard.noTasks'))}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
  props: {
    size: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      currentSize: {
        sm: 12,
        md: 8
      },
      lgSize: {
        sm: 12,
        md: 12
      },
      mdSize: {
        sm: 12,
        md: 8
      },
      smSize: {
        sm: 6,
        md: 4
      }
    };
  },
  created() {
    if (this.size != null) {
      this.currentSize.md = this.size.md;
      this.currentSize.sm = this.size.sm;
    }
  },

  watch: {
    size() {
      if (this.size != null) {
        this.currentSize.md = this.size.md;
        this.currentSize.sm = this.size.sm;
      }
    }
  },

  methods: {
    close(widgetType) {
      this.$emit('closed', widgetType);
    },
    resize(widgetType) {
      switch (this.currentSize.md) {
        case this.lgSize.md:
          this.currentSize = this.smSize;
          break;
        case this.mdSize.md:
          this.currentSize = this.lgSize;
          break;
        case this.smSize.md:
          this.currentSize = this.mdSize;
          break;
        default:
          break;
      }

      this.$emit('resized', { type: widgetType, size: this.currentSize });
    }
  }
};

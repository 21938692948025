<template>
  <v-col cols="12" :sm="currentSize.sm" :md="currentSize.md">
    <v-card flat>
      <v-app-bar dense flat color="rgba(0, 0, 0, 0)">
        <v-toolbar-title class="text-h6 pl-0"
          >{{ $t('dashboard.todos.title') }} ({{
            visibleItems.length
          }})</v-toolbar-title
        >

        <v-spacer></v-spacer>

        <v-menu
          v-if="invisibleItems && invisibleItems.length > 0"
          bottom
          left
          offset-y
          :close-on-content-click="false"
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ on: onMenu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: onTooltip }">
                <v-btn
                  v-bind="attrs"
                  v-on="{ ...onMenu, ...onTooltip }"
                  small
                  icon
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>
                {{
                  $tc('dashboard.todos.unhide', invisibleItems.length, {
                    count: invisibleItems.length
                  })
                }}
              </span>
            </v-tooltip>
          </template>

          <v-list two-line>
            <v-list-item-group>
              <template v-for="item in invisibleItems">
                <v-list-item
                  :key="`${item.processId} ${item.feedbackId} ${item.task}`"
                  @click="setTaskVisible($event, item, true)"
                >
                  <template>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="
                          (item.optional ? ' Optional: ' : '') +
                          $t(
                            `dashboard.feedbackTasks.${item.processType}.${item.task}`
                          )
                        "
                        style="font-weight: bold"
                      ></v-list-item-title>

                      <v-list-item-subtitle
                        class="text--primary"
                        v-text="
                          $t(`feedbacks.processTypes.${item.processType}`)
                        "
                      ></v-list-item-subtitle>

                      <v-list-item-subtitle
                        v-text="
                          `${item.recipientFirstName} ${item.recipientLastName}`
                        "
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small icon @click="resize({ type: 'todos' })" v-on="on">
              <v-icon>mdi-arrow-expand-horizontal</v-icon>
            </v-btn>
          </template>

          <span>{{ $t('dashboard.resize') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small icon class="drag-widget" v-on="on">
              <v-icon>mdi-drag</v-icon>
            </v-btn>
          </template>

          <span>{{ $t('dashboard.move') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small icon @click="close({ type: 'todos' })" v-on="on">
              <v-icon style="cursor: pointer">mdi-close</v-icon>
            </v-btn>
          </template>

          <span>{{ $t('dashboard.remove') }}</span>
        </v-tooltip>
      </v-app-bar>

      <v-sheet height="450" style="overflow: auto">
        <v-list two-line>
          <v-list-item-group v-if="visibleItems && visibleItems.length > 0">
            <template v-for="(item, index) in visibleItems">
              <v-list-item
                :key="`${item.processId} ${item.feedbackId} ${item.task}`"
                @click="goToTask(item)"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="
                        (item.optional ? ' Optional: ' : '') +
                        $t(
                          `dashboard.feedbackTasks.${item.processType}.${item.task}`
                        )
                      "
                      style="font-weight: bold"
                    ></v-list-item-title>

                    <v-list-item-subtitle
                      class="text--primary"
                      v-text="$t(`feedbacks.processTypes.${item.processType}`)"
                    ></v-list-item-subtitle>

                    <v-list-item-subtitle
                      v-text="
                        `${item.recipientFirstName} ${item.recipientLastName}`
                      "
                    ></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          small
                          @click="setTaskVisible($event, item, false)"
                        >
                          <v-icon> mdi-eye-off </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('dashboard.todos.hide') }}</span>
                    </v-tooltip>

                    <v-icon v-if="!active"> mdi-arrow-right </v-icon>
                    <v-icon v-else> mdi-arrow-right </v-icon>
                  </v-list-item-action>
                </template>
              </v-list-item>

              <v-divider :key="index"></v-divider>
            </template>
            <!-- <div style="text-align: right; padding: 7px 15px 3px 15px">
              <a href="#" style="text-decoration: none">alle 54 Aufgaben</a>
            </div> -->
          </v-list-item-group>
          <v-list-item-group v-else-if="loading">
            <v-list-item>
              <v-list-item-content>
                <v-progress-circular
                  indeterminate
                  color="secondary"
                ></v-progress-circular>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-list-item-group v-else>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  v-text="$t('dashboard.noTasks')"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-sheet>
    </v-card>
  </v-col>
</template>

<script>
import widgetMixin from '@/mixins/widgets';
import { mapActions, mapGetters } from 'vuex';
import { feedbackEnums } from '@/enums/feedbacks.js';

export default {
  name: 'Todos',
  mixins: [widgetMixin],
  data: () => ({
    selected: [-1],
    items: [],
    loading: true
  }),

  computed: {
    ...mapGetters({
      updated: 'dashboard/getTasksUpdated',
      currentUser: 'currentUser'
    }),

    visibleItems() {
      if (this.items === null || this.items.length === 0) {
        return [];
      }

      return this.items.filter((x) => x.visible);
    },

    invisibleItems() {
      if (this.items === null || this.items.length === 0) {
        return [];
      }

      return this.items.filter((x) => !x.visible);
    }
  },

  watch: {
    async updated(value) {
      if (value) {
        this.items = await this.getTasks();
      }
    }
  },

  methods: {
    ...mapActions({
      getTasks: 'dashboard/getTasks',
      setTaskVisibility: 'dashboard/setTaskVisibility'
    }),

    goToTask(item) {
      // TODO: Remove hardcode
      if (
        item.task === 5 ||
        item.processType === feedbackEnums.processTypes.PDP
      ) {
        if (this.currentUser.id === item.recipientId) {
          this.$router.push({
            name: 'MyFeedbacks'
          });
        } else {
          this.$router.push({
            name: 'FeedbacksAsDonor',
            query: { kw: item.recipientFirstName }
          });
        }
      } else {
        this.$router.push({
          name: 'FeedbackForm',
          params: { id: item.feedbackId }
        });
      }
    },

    async setTaskVisible(event, task, isVisible) {
      event.stopPropagation();
      task.visible = isVisible;
      await this.setTaskVisibility({ tasks: [task], visible: isVisible });
    }
  },

  async mounted() {
    this.items = await this.getTasks();
    this.loading = false;
  }
};
</script>

<style></style>

<template>
  <v-col cols="12" :sm="currentSize.sm" :md="currentSize.md">
    <v-card flat>
      <v-app-bar dense flat color="rgba(0, 0, 0, 0)">
        <v-toolbar-title class="text-h6 pl-0">
          {{ $t('dashboard.talkroundInfosTitle') }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              icon
              @click="resize({ type: 'talkroundInfos' })"
              v-on="on"
            >
              <v-icon>mdi-arrow-expand-horizontal</v-icon>
            </v-btn>
          </template>

          <span>{{ $t('dashboard.resize') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small icon class="drag-widget" v-on="on">
              <v-icon>mdi-drag</v-icon>
            </v-btn>
          </template>

          <span>{{ $t('dashboard.move') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              icon
              @click="close({ type: 'talkroundInfos' })"
              v-on="on"
            >
              <v-icon style="cursor: pointer">mdi-close</v-icon>
            </v-btn>
          </template>

          <span>{{ $t('dashboard.remove') }}</span>
        </v-tooltip>
      </v-app-bar>

      <v-card-text>
        <div v-html="$t('dashboard.talkroundInfosText')"></div>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import widgetMixin from '@/mixins/widgets';

export default {
  name: 'TalkroundInfos',
  mixins: [widgetMixin],
  data: () => ({})
};
</script>

<style lang="scss" scoped>
ul > li {
  margin-bottom: 1rem;
}
</style>
